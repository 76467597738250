import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import {
  DataToPinItem,
  GetAllPinnedItemsResponse,
  PinItemResponse,
  PinnedItem,
} from "./pinned-models";
import { Observable, Subject } from "rxjs";
import { Company } from "src/app/dashboard/shared-data.service";

@Injectable({
  providedIn: "root",
})
export class PinnedService {
  constructor(private http: HttpClient) {}

  sendNewPinnedItemToComponent: Subject<PinItemResponse> = new Subject();
  unpinItemSubject: Subject<string> = new Subject();
  changePinStatus$: Subject<{ pinUuid: string; status: number }> =
    new Subject();

  newPinAdded$: Subject<any> = new Subject();

  getCompanyUuid(companyId: number): Promise<any> {
    const url = `${environment.apiUrl}/company/${companyId}/company-uuid`;
    return this.http.get(url).toPromise();
  }

  getAllPinnedItems(
    companyUuid: string,
    status: string = null,
  ): Promise<GetAllPinnedItemsResponse> {
    let url = `${environment.apiUrl}/company/${companyUuid}/pin`;

    if (status) {
      url += `?status=${status}`;
    }

    return this.http.get<GetAllPinnedItemsResponse>(url).toPromise();
  }

  pinItem(data: DataToPinItem, companyUuid: string): Promise<PinItemResponse> {
    const url = `${environment.apiUrl}/company/${companyUuid}/pin`;
    return this.http.post<PinItemResponse>(url, data).toPromise();
  }

  updatePin(companyUuid: string | Company, itemUuid: string): Promise<any> {
    const url = `${environment.apiUrl}/company/${companyUuid}/pin/${itemUuid}`;
    return this.http.put(url, {}).toPromise();
  }

  retrieveAllPinnedItems(
    personFilter = null,
    assignedToFilter = null,
    timeFrameFilter = null,
    pinnedStatusFilter = null,
    first: number,
    rows: number,
  ): Promise<{ pinned_items: PinnedItem[]; total_count: number }> {
    let params = `?rows=${rows}&start=${first}`;

    if (personFilter) {
      params += `&personFilter=${personFilter}`;
    }

    if (assignedToFilter) {
      params += `&assignedToFilter=${assignedToFilter}`;
    }

    if (timeFrameFilter) {
      if (Array.isArray(timeFrameFilter)) {
        params += `&timeFrameFilter=custom_date&startDate=${timeFrameFilter[0]}&completedDate=${timeFrameFilter[1]}`;
      } else {
        params += `&timeFrameFilter=${timeFrameFilter}`;
      }
    }

    if (pinnedStatusFilter) {
      params += `&pinnedStatus=${pinnedStatusFilter}`;
    }

    const url = `${environment.apiUrl}/company/pinned-dashboard${params}`;

    return this.http
      .get<{ pinned_items: PinnedItem[]; total_count: number }>(url)
      .toPromise();
  }

  getPinnedItem(companyUuid: string, itemUuid: string) {
    return this.http.get<{}>(
      `${environment.apiUrl}/company/${companyUuid}/pin/${itemUuid}`,
    );
  }

  assignPin(
    companyUuid: string,
    itemUuid: string,
    assignee: string,
  ): Observable<{}> {
    return this.http.post<{}>(
      `${environment.apiUrl}/company/${companyUuid}/pin/${itemUuid}`,
      { assignee },
    );
  }

  changePinStatus(
    companyUuid: string,
    itemUuid: string,
    status: number,
  ): Observable<{}> {
    return this.http.patch<{}>(
      `${environment.apiUrl}/company/${companyUuid}/pin/${itemUuid}`,
      { status },
    );
  }

  unpinItem(companyUuid: string, itemUuid: string): Observable<{}> {
    return this.http.delete<{}>(
      `${environment.apiUrl}/company/${companyUuid}/pin/${itemUuid}`,
    );
  }
}
