import { Company, User } from "src/app/dashboard/shared-data.service";
import { Note } from "../notes/note-models";
import { Job } from "../jobs-and-tasks/job.model";

export enum PinnedStatus {
  "Pinned" = 1,
  "Pinned Complete" = 2,
}

export enum PinnedItemType {
  Note = "Note",
  TrackedItem = "Client Task",
  Job = "Job",
  Thread = "Thread",
  ConversationMessagePin = "Message",
}

export enum PinnedModels {
  Note = "Note",
  TrackedItem = "TrackedItem",
  Job = "Job",
  Thread = "Thread",
  Message = "ConversationMessagePin",
}

export enum PinnedModelsApps {
  Note = "company",
  TrackedItem = "conversations",
  Job = "jobs",
  Thread = "email_reader",
  Message = "conversations",
}

export type PinnedItem = {
  uuid?: string;
  id?: string;
  pinned_by?: User;
  pinned_dt?: Date;
  client_company: Company | string;
  pinned_status: number;
  model_name: string;
  content_object: Note | Job | any;
  assigned_to?: User;
};

export type GetAllPinnedItemsResponse = {
  pinned_items: PinnedItem[];
};

export type DataToPinItem = {
  model_name: string;
  model_app_name: string;
  object_pk: string | number;
};

export type PinItemResponse = {
  message?: string;
  pinned_item: PinnedItem;
  item_uuid?: string;
};

export function getAppNameByModel(model: PinnedModels) {

  switch(model) {
    case PinnedModels.Job:
      return PinnedModelsApps.Job;

    case PinnedModels.Message:
      return PinnedModelsApps.Message;

    case PinnedModels.Note:
      return PinnedModelsApps.Note;

    case PinnedModels.Thread:
      return PinnedModelsApps.Thread;

    case PinnedModels.TrackedItem:
      return PinnedModelsApps.TrackedItem;

    default:
      throw new Error("Invalid usage");
  }
}
