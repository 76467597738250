import { DashboardStore } from "src/app/dashboard/dashboard.store";
import { DashboardService } from "./dashboard.service";
import { Injectable, AfterViewInit } from "@angular/core";
import { ConversationsService } from "./conversations/conversations.service";

@Injectable({ providedIn: "root" })
export class EventsService {
  constructor(
    private dashboardService: DashboardService,
    private dashboardStore: DashboardStore,
    private conversationsService: ConversationsService
  ) {
    console.log("Events Service Initialized");
  }

  handleEvent(message, conversation) {
    if (message.meta) {
      switch (message.meta.EVENT) {
        // case "NEW_TRACKED_ITEM":
        //   // console.log("NEW CLIENT TASK");
        //   this.new_tracked_item(message, conversation);
        //   break;
        // case "UPDATE_TRACKED_ITEM":
        //   // console.log("UPDATE CLIENT TASK");
        //   this.update_tracked_item(message, conversation);
        //   break;
        case "NEW_COMMENT":
          // console.log("NEW COMMENT");
          this.new_comment(message, conversation);
        default:
          break;
      }
    }
  }

  new_tracked_item(message, conversation): void {
    // Check if has client tasks
    if (conversation.tracked_items.length > 0) {
      // If yes, check if the last update is not the same as this one
      if (
        conversation.tracked_items.filter(
          (t) => t.uuid == message.tracked_item.uuid
        ).length == 0
      ) {
        conversation.tracked_items.push(message.tracked_item);
      }
    } else {
      conversation.tracked_items.push(message.tracked_item);
    }

    this.conversationsService.getTrackedItemsTabCountSubject.next(
      conversation.uuid
    );
    // this.dashboardService.getOpenTrackedItemsCount.next(null);
  }

  update_tracked_item(message, conversation): void {
    // RESPONSE IN CLIENT TASK / ACCEPT/DECLINE
    // console.log("update_tracked_item", message, conversation);
    if (conversation.id === message.tracked_item.conversation) {
      // console.log("FOUND THE CLIENT TASK", message, conversation);
      for (let y = 0; y < conversation.tracked_items.length; y++) {
        if (conversation.tracked_items[y].id === message.tracked_item.id) {
          conversation.tracked_items[y] = message.tracked_item;

          // This updates the new Client Task Panel Drawer
          if (this.dashboardStore.selectedTrackedItem != null) {
            if (
              this.dashboardStore.selectedTrackedItem.id ==
              conversation.tracked_items[y].id
            ) {
              this.dashboardStore.selectTrackedItem(
                message.tracked_item,
                this.dashboardStore.currentCompany
              );
            }
          }

          // This updates the Client Task card in the workspace
          this.dashboardService.updateTrackedItems.next({
            company: conversation.company,
          });

          this.dashboardService.getOpenTrackedItemsCount.next(null);
          // this.conversationsService.getTrackedItemsTabCountSubject.next(null);
          break;
        }
      }
    }

    if (message.meta.add_file) {
      if (message.tracked_item.type == 2) {
        for (let x = 0; x < conversation.length; x++) {
          if (conversation.id === message.tracked_item.conversation) {
            conversation.files.tracked_items.push(message.tracked_item);
          }
        }
      }
    }
  }

  new_comment(message, conversation): void {
    console.log(message, conversation);
    if (conversation.id === message.comment.conversation_id) {
      for (let y = 0; y < conversation.tracked_items.length; y++) {
        // Search for the client task in that conversation
        if (
          conversation.tracked_items[y]["id"] ===
          message.comment.tracked_item.id
        ) {
          // If has comments on client task, check if the last is the same
          if (conversation.tracked_items[y].comments.length > 0) {
            if (
              conversation.tracked_items[y].comments[
                conversation.tracked_items[y].comments.length - 1
              ].uuid !== message.comment.uuid
            ) {
              conversation.tracked_items[y]["comments"].push(message.comment);
              break;
            }
          } else {
            conversation.tracked_items[y]["comments"].push(message.comment);
            break;
          }
        }
      }
    }
  }
}
