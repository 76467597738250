import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MessageBubbleService {
  _togglePin = new Subject();
  togglePin$ = this._togglePin.asObservable();

  togglePin() {
    this._togglePin.next(null);
  }
}
