import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";
import {
  Firestore,
  collection,
  query,
  orderBy,
  limit,
  onSnapshot,
  serverTimestamp,
  collectionData,
  deleteDoc,
  doc,
  updateDoc,
} from "@angular/fire/firestore";
import { HttpClient } from "@angular/common/http";

import { environment } from "../../environments/environment";
import { PinnedService } from "./main/accountant/pinned-panel/pinned.service";
import { DashboardStore } from "./dashboard.store";
import { MessageBubbleService } from "./conversations/message-bubble/message-bubble.service";
import { EventsService } from "./events.service";
import { WorkspacesService } from "./main/accountant/workspaces.service";
import {
  PinnedModels,
  PinnedModelsApps,
} from "./main/accountant/pinned-panel/pinned-models";

type CreatePinnedMessageModelResponse = {
  model_message_uuid: string;
  company_uuid: string;
};

@Injectable({ providedIn: "root" })
export class ChatService {
  clients = [];
  should_receive_events = false;
  isSubscribedToChats = false;
  isSubscribedToUpdates = false;
  activeConversationUuid = null;

  messagesAreLoaded = false;

  showBotMessages = new Subject();
  chatLoaded$ = new Subject();
  newMessage = new Subject();
  setReplyingTo = new Subject();

  currentChatSubscription = null;

  pinStatus = {
    unpinned: "unpinned",
    pinned: "pinned",
    completed: "completed",
  };

  constructor(
    private firestore: Firestore,
    private eventsService: EventsService,
    private pinnedService: PinnedService,
    public dashboardStore: DashboardStore,
    private http: HttpClient,
    private messageBubbleService: MessageBubbleService,
    private workspacesService: WorkspacesService,
  ) {
    this.dashboardStore.clientsLoaded.subscribe((sub: any) => {
      this.subscribeToChats(this.dashboardStore.clients);
    });
  }

  getLastElement(arr) {
    return arr[arr.length - 1];
  }

  getFirestoreMessages(uuid, lim = 100): Observable<any[]> {
    const messagesCollection = collection(
      this.firestore,
      `chats/${uuid}/messages`,
    );
    const messagesQuery = query(
      messagesCollection,
      orderBy("date", "desc"),
      limit(lim),
    );

    return new Observable((observer) => {
      const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
        const data = [];
        snapshot.docChanges().forEach((change) => {
          data.push({
            ...change.doc.data(),
            id: change.doc.id,
            type: change.type,
          });
        });

        observer.next(data.reverse());
      });

      return () => unsubscribe();
    });
  }

  updateConversationMessages(conversation, newMessages) {
    let wasAdded = false;
    for (let x = 0; x < newMessages.length; x++) {
      if (newMessages[x].type == "added") {
        conversation.company_conversation.messages.push(newMessages[x]);
        wasAdded = true;
      } else if (newMessages[x].type == "modified") {
        for (
          let y = 0;
          y < conversation.company_conversation.messages.length;
          y++
        ) {
          if (
            conversation.company_conversation.messages[y].id ==
            newMessages[x].id
          ) {
            conversation.company_conversation.messages[y] = newMessages[x];
          }
        }
      } else if (newMessages[x].type == "removed") {
        conversation.company_conversation.messages =
          conversation.company_conversation.messages.filter(
            (m) => m.id !== newMessages[x].id,
          );
      }
    }

    if (wasAdded) {
      this.chatLoaded$.next({});
    }

    this.messageBubbleService.togglePin();
    this.workspacesService.refreshPinnedMessages.next(null);
  }

  changeConversationSubscription(conversation, lim = 100) {
    this.dashboardStore.changeConversationUUID(
      conversation.company_conversation.uuid,
    );

    if (conversation._sub != null) {
      conversation._sub.unsubscribe();
    }

    conversation.loading = true;
    conversation.company_conversation.messages = [];

    conversation._sub = this.getFirestoreMessages(
      conversation.company_conversation.uuid,
      lim,
    ).subscribe((sub: any) => {
      if (sub.length > 0) {
        // Handle modified or new messages
        this.updateConversationMessages(conversation, sub);

        // Format dates
        this.formatDates(conversation.company_conversation.messages);

        let update = this.getLastElement(
          conversation.company_conversation.messages,
        );

        this.eventsService.handleEvent(
          update,
          conversation.company_conversation,
        );

        // Mark as unread if it is not the current conversation
        if (this.dashboardStore.activeConversationUUID != null) {
          if (
            this.dashboardStore.activeConversationUUID !=
            conversation.company_conversation.uuid
          ) {
            if (
              update.tracked_item != null ||
              update.system_message != null ||
              update.reminder == true
            ) {
              return;
            } else {
              this.dashboardStore.setReadStatus(
                conversation.company_conversation.id,
                false,
              );
            }
          }
        }

        if (
          conversation.company_conversation.loaded == undefined ||
          conversation.company_conversation.loaded == null
        ) {
          conversation.company_conversation.loaded = true;
        }

        if (sub.filter((m) => m.type == "added").length > 0) {
          this.chatLoaded$.next(conversation.company_conversation.uuid);
        }
      }

      conversation.loading = false;
    });
  }

  subscribeToChats(conversations) {
    conversations.forEach((c) => {
      c._sub = this.getFirestoreMessages(
        c.company_conversation.uuid,
        1,
      ).subscribe((sub: any) => {
        if (sub.length > 0) {
          for (let x = 0; x < conversations.length; x++) {
            // Find the conversation it belongs to
            if (
              conversations[x].company_conversation.uuid ==
              c.company_conversation.uuid
            ) {
              this.updateConversationMessages(conversations[x], sub);

              let update = this.getLastElement(
                conversations[x].company_conversation.messages.filter(
                  (m) => m.type == "added",
                ),
              );

              if (update != null) {
                // Mark as unread if it is not the current conversation AND it is not a system message
                if (this.dashboardStore.activeConversationUUID != null) {
                  if (
                    this.dashboardStore.activeConversationUUID !=
                      conversations[x].company_conversation.uuid &&
                    conversations[x].company_conversation.loaded == true
                  ) {
                    if (
                      update.tracked_item != null ||
                      update.system_message != null ||
                      update.reminder == true
                    ) {
                      return;
                    } else {
                      this.dashboardStore.setReadStatus(
                        conversations[x].company_conversation.id,
                        false,
                      );
                    }
                  }
                }
              }

              // Mark as unread if it is not the current conversation
              if (
                conversations[x].company_conversation.loaded == undefined ||
                conversations[x].company_conversation.loaded == null
              ) {
                conversations[x].company_conversation.loaded = true;
              }

              // this.chatLoaded.next(c.company_conversation.uuid);
            }
          }
        }

        if (!this.messagesAreLoaded) this.messagesAreLoaded = true;
      });
    });

    this.isSubscribedToChats = true;
  }

  formatDates(messages) {
    messages.forEach((m) => {
      if (m.date != null) {
        if (typeof m.date === "object") {
          if (this.isFunction(m.date.toDate)) {
            m.date = m.date.toDate();
          }
        } else if (typeof m.date === "number") {
          m.date = new Date(m.date);
        }
      }

      if (m.replyingTo != null) {
        if (m.replyingTo.date != null) {
          if (typeof m.replyingTo.date === "object") {
            if (this.isFunction(m.replyingTo.date.toDate)) {
              m.replyingTo.date = m.replyingTo.date.toDate();
            }
          } else if (typeof m.replyingTo.date === "number") {
            m.replyingTo.date = new Date(m.replyingTo.date);
          }
        }
      }
    });
  }

  isFunction(functionToCheck) {
    return (
      functionToCheck &&
      {}.toString.call(functionToCheck) === "[object Function]"
    );
  }

  getFirestoreServerDate() {
    return serverTimestamp();
  }

  getChatByConversationUUID(conversation_uuid) {
    for (let x = 0; x < this.dashboardStore.clients.length; x++) {
      if (
        this.dashboardStore.clients[x].company_conversation.uuid ==
          conversation_uuid ||
        this.dashboardStore.clients[x].id == conversation_uuid
      ) {
        return this.dashboardStore.clients[x].company_conversation;
      }
    }
    return null;
  }

  getClientByConversationUUID(conversation_uuid) {
    for (let x = 0; x < this.dashboardStore.clients.length; x++) {
      if (
        this.dashboardStore.clients[x].company_conversation.uuid ==
          conversation_uuid ||
        this.dashboardStore.clients[x].id == conversation_uuid
      ) {
        this.dashboardStore.activeConversationUUID =
          this.dashboardStore.clients[x].company_conversation.uuid;
        return this.dashboardStore.clients[x];
      }
    }
    return null;
  }

  async changeReactions(message: any, conversationUuid: string) {
    const messageRef = doc(
      this.firestore,
      `chats/${conversationUuid}/messages`,
      message.id,
    );

    try {
      await updateDoc(messageRef, {
        ...message,
      });
    } catch (error) {}
  }

  async editMessage(message: any, oldText: string, conversationUuid: string) {
    const history =
      message.history && Array.isArray(message.history) ? message.history : [];
    history.push({ timestamp: new Date().toISOString(), text: oldText });

    const messageRef = doc(
      this.firestore,
      `chats/${conversationUuid}/messages`,
      message.id,
    );

    try {
      await updateDoc(messageRef, {
        message: message.message,
        edited: true,
        last_edited: this.getFirestoreServerDate(),
        history: history,
      });

      message.edit_mode = false;
    } catch (error) {
      console.error("Error updating message:", error);
    }
  }

  async deleteMessage(message, conversation_uuid) {
    const messageRef = doc(
      this.firestore,
      `chats/${conversation_uuid}/messages`,
      message.id,
    );

    try {
      await deleteDoc(messageRef);
      console.log("Message deleted successfully");
    } catch (error) {
      console.error("Error deleting message:", error);
    }
  }

  getAllHistory(conversation_uuid) {
    return;
  }

  createPinnedMessageModel(message): Promise<CreatePinnedMessageModelResponse> {
    const url = `${environment.apiUrl}/conversations/pin/message`;
    return this.http
      .post<CreatePinnedMessageModelResponse>(url, {
        message,
        message_id: message.id || message.firestore_message_id,
      })
      .toPromise();
  }

  async pinMessageOnFirestore(
    conversation_uuid: string,
    messageId: string,
    pinStatus: string,
  ) {
    console.log(
      "pinMessageOnFirestore",
      conversation_uuid,
      messageId,
      pinStatus,
    );

    const messageRef = doc(
      this.firestore,
      `chats/${conversation_uuid}/messages`,
      messageId,
    );

    try {
      await updateDoc(messageRef, {
        pinned: pinStatus,
      });

      return pinStatus;
    } catch (error) {
      console.error("Error updating pin status:", error);
      return null; // Handle the error as needed
    }
  }

  async pinMessage(
    message,
    conversation_uuid,
    company_uuid,
    manualStatus: "pinned" | "completed" | "unpinned" | null = null,
  ) {
    let pinStatus = null;

    if (message.pinned == true || message.pinned == this.pinStatus.pinned) {
      pinStatus = this.pinStatus.completed;
      message.pinned = pinStatus;
    } else if (
      message.pinned == false ||
      message.pinned == this.pinStatus.unpinned ||
      message.pinned == null
    ) {
      pinStatus = this.pinStatus.pinned;
      message.pinned = pinStatus;
    } else if (message.pinned == this.pinStatus.completed) {
      pinStatus = this.pinStatus.unpinned;
      message.pinned = pinStatus;
    }

    if (manualStatus !== null) {
      pinStatus = manualStatus;
    }

    // Create message model
    const { model_message_uuid } = await this.createPinnedMessageModel(message);

    // Pin message model
    const data = {
      model_name: PinnedModels.Message,
      model_app_name: PinnedModelsApps.Message,
      object_pk: model_message_uuid,
    };

    const { item_uuid } = await this.pinnedService.pinItem(data, company_uuid);

    message.pinUuid = item_uuid;

    // Save pin uuid in firestore
    const messageRef = doc(
      this.firestore,
      `chats/${conversation_uuid}/messages`,
      message.id,
    );

    try {
      await updateDoc(messageRef, {
        pinUuid: item_uuid,
      });
    } catch (error) {
      console.error("Error setting pinUuid for the message:", error);
      // Handle the error as needed
    }

    // Pin on firestore
    this.pinMessageOnFirestore(conversation_uuid, message.id, pinStatus);

    return pinStatus;
  }

  async toggleMessageVisibility(message: any, conversation_uuid: string) {
    const messageRef = doc(
      this.firestore,
      `chats/${conversation_uuid}/messages`,
      message.id,
    );

    try {
      await updateDoc(messageRef, {
        deleted: !message.deleted,
      });

      return !message.deleted;
    } catch (error) {
      console.error("Error toggling message visibility:", error);
      return message.deleted; // Return the previous state in case of an error
    }
  }
}
